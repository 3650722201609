import * as yup from "yup";

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  sameAsVehicleOwner: yup
    .string()
    .default("")
    .required("This field is required."),
  vehicleDriversFirstName: yup
    .string()
    .default("")
    .when("sameAsVehicleOwner", {
      is: "No",
      then: yup.string().required("This field is required."),
      otherwise: yup.string().default(""),
    }),
  vehicleDriversMiddleName: yup.string().default(""),
  vehicleDriversLastName: yup
    .string()
    .default("")
    .when("sameAsVehicleOwner", {
      is: "No",
      then: yup.string().required("This field is required."),
      otherwise: yup.string().default(""),
    }),
  vehicleDriversStreetAddress: yup.string().default(""),
  vehicleDriversCity: yup.string().default(""),
  vehicleDriversState: yup.string().default(""),
  vehicleDriversZip: yup.string().default(""),
  vehicleDriversHomePhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  vehicleDriversCellPhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  vehicleDriversWorkPhone: yup
    .string()
    .default("")
    .matches(
      /^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/,
      "Please enter a valid phone number.",
    ),
  vehicleDriversEmail: yup
    .string()
    .default("")
    .email("Please enter a valid email address."),
  vehicleDriversDateOfBirth: yup
    .string()
    .default("")
    .test("dateOfBirth", "Please enter a valid date of birth.", (value) => {
      const date = new Date(value);
      const minYear = 1900;
      const maxYear = new Date().getFullYear();
      const year = date.getFullYear();
      if (year < minYear || year > maxYear) return false;
      return !isNaN(date.getTime()) || value === "";
    }),
  vehicleDriversLicenseNumber: yup.string().default(""),
  vehicleDriversLicenseState: yup.string().default(""),
  didDriverReceiveTicket: yup.string().default(""),
  driverTicketReason: yup.string().default("").max(max, message),
  wasDriverInjured: yup.string().default(""),
  driverInjuryDescription: yup.string().default("").max(max, message),
  didDriverSeekMedical: yup.string().default(""),
  vehicleDriversPurposeOfUse: yup.string().default(""),
};

export default schema;
export { max };
