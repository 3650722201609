import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Input from "../../../../../../../../Input/Input";
import useScreen from "../hooks/useScreen";

const Screen = ({ form, screenIndex, wizard }) => {
  const reporterFirstNameValue = form?.watch("reporterFirstName");
  const reporterLastNameValue = form?.watch("reporterLastName");
  const reporterPhoneValue = form?.watch("reporterPhone");
  const reporterEmailValue = form?.watch("reporterEmail");
  const emailValidation = form?.formState.errors.reporterEmail;
  const phoneValidation = form?.formState.errors.reporterPhone;

  const isInvalid = useMemo(() => {
    if (
      !reporterFirstNameValue ||
      !reporterLastNameValue ||
      !reporterPhoneValue ||
      !reporterEmailValue
    )
      return true;
    if (emailValidation || phoneValidation) return true;
    return false;
  }, [
    reporterFirstNameValue,
    reporterLastNameValue,
    reporterPhoneValue,
    reporterEmailValue,
    emailValidation,
    phoneValidation,
  ]);

  useScreen({ isInvalid, screenIndex, wizard });
  return (
    <>
      <Row>
        <Col xs={12} sm={6}>
          <Input
            error={form?.formState.errors.reporterFirstName}
            id="reporterFirstName"
            label="Reporter's First Name"
            touched={form?.formState.touchedFields.reporterFirstName}
            {...form?.register("reporterFirstName", {
              onBlur: () => form?.trigger("reporterFirstName"),
            })}
          />
        </Col>
        {/* <Col xs={12} sm={6}>
          <Input
            id="reporterMiddleName"
            label="Reporter's middle name"
            {...form?.register('reporterMiddleName')}
          />
        </Col> */}
        <Col xs={12} sm={6}>
          <Input
            error={form?.formState.errors.reporterLastName}
            id="reporterLastName"
            label="Reporter's Last Name"
            touched={form?.formState.touchedFields.reporterLastName}
            {...form?.register("reporterLastName", {
              onBlur: () => form?.trigger("reporterLastName"),
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Input
            error={form?.formState.errors.reporterPhone}
            id="reporterPhone"
            label="Reporter's Phone Number"
            type="tel"
            touched={form?.formState.touchedFields.reporterPhone}
            {...form?.register("reporterPhone", {
              onChange: () => form?.trigger("reporterPhone"),
              onBlur: () => form?.trigger("reporterPhone"),
            })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            error={form?.formState.errors.reporterEmail}
            id="reporterEmail"
            label="Reporter's Email"
            type="email"
            touched={form?.formState.touchedFields.reporterEmail}
            {...form?.register("reporterEmail", {
              onBlur: () => form?.trigger("reporterEmail"),
            })}
          />
        </Col>
      </Row>
    </>
  );
};

export default Screen;
