import * as yup from "yup";

const schema = {
  policyNumber: yup.string().default("").required("This field is required."),
  dateOfAccident: yup
    .string()
    .default("")
    .required("This field is required.")
    .test("date", "Please enter a valid date of accident.", (value) => {
      const date = new Date(value);
      const minYear = 2000;
      const maxYear = new Date().getFullYear();
      const year = date.getFullYear();
      if (year < minYear || year > maxYear) return false;
      return !isNaN(date.getTime());
    }),
  timeOfAccident: yup.string().default("").required("This field is required."),
  locationOfAccidentStreetAddress: yup.string().default(""),
  locationOfAccidentCity: yup.string().default(""),
  locationOfAccidentState: yup
    .string()
    .default("")
    .required("This field is required."),
  locationOfAccidentZip: yup
    .string()
    .default("")
    .matches(
      /^(?:\d{5}(?:[-\s]?\d{4})?|\s*)$/,
      "Please enter a valid zip code.",
    ),
};

export default schema;
